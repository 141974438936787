import React from "react"

import Layout from "../layout"
import SEO from "../components/seo"
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Hero from "../components/heros/about"

const SecondPage = () => (
  <Layout>
    <SEO title="About Us" />

    <Hero>
      <Container fixed style={{ textAlign: 'center', paddingTop: '4rem' }}>
        <Typography variant="h1" style={{ marginBottom: '2rem' }}>
          About Us
        </Typography>
        <Typography variant="h4" >
          This is what we do.
        </Typography>
      </Container>
    </Hero>

    <Container fixed style={{ padding: '2rem' }}>
      <Typography variant="h6" component="p">The story of Cluck Chicken began long ago in the depths of Alabama. Anative of Auburn, Rachel Tremaine lives and breathes chicken. A true southern-belle, her passion for our winged friends is second only to herpassion for hospitality.</Typography>
      <br /><br />
      <Typography variant="h6" component="p">After moving to Denver, Tremaine opened the Cluck Truck to bring her passion for chicken to the people and bring them the fried chicken theydidn’t know they’d been missing. Specializing in hand breaded chicken fingers and hand cut fries the CLUCK TRUCK quickly became a huge hit!</Typography>
      <br /><br />
      <Typography variant="h6" component="p">But chickens need a home to roost and CLUCK is no different. In 2019 Tremaine opened the very first stand-up CLUCK location right inside theDive Inn, Denver’s famed dive bar!</Typography>
      <br /><br />
      <Typography variant="h6" component="p">So come try Denver’s finest southern style chicken and check back often, we change our menu from time to time so that you can experience the best the chicken world has to offer.</Typography>
      <br /><br />
      <Typography variant="h6" component="p">Do yourself a favor. Get Clucked.</Typography>

    </Container>
  </Layout>
)

export default SecondPage
